import { makeStyles } from '@material-ui/core';
import centerAreaBackground from "../../assets/images/center-area-backround.png";
import contentBackground from '../../assets/images/content-background.png';
import contentBackgroundWithoutIcons from '../../assets/images/content-background-without-icons.png';

const useStyles = makeStyles(theme => ({
  aboutUs: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  lineIcons: {
    position: 'absolute',
    width: '100%',
    marginTop: '-15vh'
  },
  aboutUsTopArea: {
    overflow: 'auto',
    backgroundImage: `url(${centerAreaBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    paddingTop: '30vh',
    paddingBottom: "20vh",
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down("sm")]: {
      paddingTop: "35vh",
    }
  },
  aboutUsTitleArea: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  aboutUsTitleText: {
    color: theme.palette.secondary.main,
    fontFamily: "Frenchpress, Roboto",
    letterSpacing: "4%",
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.h3.fontSize
    }
  },
  aboutUsTextArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  aboutUsParagraph: {
    marginTop: 36,
    textAlign: 'center',
    fontFamily: 'Dosis, Roboto',
    fontWeight: 700,
    color: theme.palette.text.secondary,
    width: '60%',
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.body2.fontSize,
      width: '80%',
      marginTop: 24,
    }
  },
  aboutUsQuoteArea: {
    marginTop: "-20vh",
    width: "100%",
    backgroundImage: `url(${contentBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    paddingTop: "30vh",
    paddingBottom: "20vh",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 200,
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${contentBackgroundWithoutIcons})`,
      marginTop: "-40%",
      paddingTop: "15vh",
      paddingBottom: "10vh",
    }
  },
  aboutUsQuoteText: {
    fontFamily: 'Frenchpress, Roboto',
    fontWeight: 400,
    letterSpacing: "4%",
    color: theme.palette.secondary.main,
    textAlign: 'center',
    width: '80%',
    marginTop: 16,
    marginBottom: 8,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.h3.fontSize
    }
  },
  aboutUsQuoteSubtitleText: {
    color: theme.palette.text.secondary,
    fontWeight: 700,
    fontFamily: 'Dosis, Roboto',
    marginBottom: 42,
  },
  missionArea: {
    width: '100vw',
    marginTop: "-20vh",
    backgroundColor: '#fafafa',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: "20vh",
    zIndex: 0,

  },
  missionItem: {
    display: 'flex',
    alignItems: 'center',
    width: '70%',
    marginTop: 32,
    [theme.breakpoints.down("sm")]: {
      flexDirection: 'column',
      width: '80%'
    }
  },
  missionItemTitle: {
    fontWeight: 400,
    fontFamily: 'Frenchpress, Roboto',
    letterSpacing: "4%",
    flex: 0.2,
    WebkitTextStroke: '1px'
  },
  missionItemDescription: {
    fontFamily: 'Dosis, Roboto',
    fontWeight: 700,
    flex: 1,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("sm")]: {
      textAlign: 'center',
      fontSize: theme.typography.body2.fontSize
    }
  },
  teamArea: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#fafafa',
    paddingLeft: 110,
    paddingRight: 110,
    paddingTop: '10vh',
    paddingBottom: '15vh',
    marginBottom: "-10vh",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      paddingRight: 20,
      justifyContent: 'center',
    }
  },
  teamMembersImageArea: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-end',
    height: "100%",
  },
  teamMembersImage: {
  },
  teamMembersInfoArea: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.down("sm")]: {
      width: '80%',
      alignItems: 'center'
    }
  },
  specialistTeamTitleText: {
    color: theme.palette.secondary.main,
    fontFamily: 'Frenchpress, Roboto',
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.h3.fontSize,
      textAlign: 'center'
    }
  },
  teamSpecialistNameText: {
    color: theme.palette.primary.main,
    fontFamily: 'Frenchpress, Roboto',
    marginTop: 20,
    WebkitTextStroke: "0.7px",
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.h4.fontSize,
      textAlign: 'center',
    }
  },
  teamSpecialistDescriptionParagraph: {
    color: theme.palette.text.secondary,
    fontFamily: 'Dosis, Roboto',
    fontWeight: 700,
    marginTop: 24,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.body2.fontSize,
      textAlign: 'center',
    }
  },
  specialistContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
  },
  openQuotesIcon: {
    [theme.breakpoints.down("sm")]: {
      width: 24,
      height: 24,
    }
  },
  closeQuotesIcon: {
    [theme.breakpoints.down("sm")]: {
      width: 24,
      height: 24,
    }
  }
}));

export default useStyles;