import React, { useEffect, useState } from "react";
import DefaultLayout from "../layouts/default-layout";
import useStyles from "../styles/sobre/styles";
import { Helmet } from "react-helmet";
import lineIcons from "../assets/images/line-icons.png";
import closeQuotesIcon from "../assets/icons/close-quotes-icon.png";
import openQuotesIcon from "../assets/icons/open-quotes-icon.png";
import { Typography, Hidden, useTheme } from "@material-ui/core";
import teamMembersImage from "../assets/images/team-members-image.png";

function Sobre() {
  const classes = useStyles();
  const theme = useTheme();
  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    setPageLoaded(true);
  }, []);

  if (!pageLoaded) return null;
  return (
    <DefaultLayout>
      <div className={classes.aboutUs}>
        <Helmet>
          <title>Sobre | Smart Baby Box</title>
        </Helmet>
        <Hidden smDown>
          <img src={lineIcons} alt="" className={classes.lineIcons} />
        </Hidden>
        <div className={classes.aboutUsTopArea}>
          <div className={classes.aboutUsTitleArea}>
            <Typography variant="h1" className={classes.aboutUsTitleText}>
              sobre nós
            </Typography>
          </div>
          <div className={classes.aboutUsTextArea}>
            <Typography variant="h6" className={classes.aboutUsParagraph}>
              Somos Camila, mãe da Laís e da Lisa, e Carolina, mãe do Afonso.
              Sabendo da importância do brinquedo na vida dos nossos filhos,
              sempre buscamos escolher cuidadosamente os brinquedos adequados
              para oferecer a eles.
            </Typography>
            <Typography variant="h6" className={classes.aboutUsParagraph}>
              No entanto, na correria da vida de mãe, mulher e trabalhadora, nos
              vimos sem o tempo suficiente para pensar todos os meses em qual
              seria o melhor estímulo para aquela fase em que nossos filhos
              estavam.
            </Typography>
            <Typography variant="h6" className={classes.aboutUsParagraph}>
              Pensamos então que outras mães poderiam passar pelo mesmo
              problema, por isso resolvemos criar uma assinatura em que o
              brinquedo adequado chegasse no tempo adequado! A mãe não precisa
              se preocupar com nada além de aproveitar os momentos com o seu
              bebê - que crescem tão rápido!
            </Typography>
            <Typography variant="h6" className={classes.aboutUsParagraph}>
              Com a Smart Baby Box, acabam os infindáveis minutos em pesquisas
              na internet. Todos os meses os melhores estímulos para o seu bebê
              estarão na sua casa!{" "}
            </Typography>
          </div>
        </div>
        <div className={classes.aboutUsQuoteArea}>
          <img
            src={openQuotesIcon}
            alt="Abre aspas"
            className={classes.openQuotesIcon}
          />
          <Typography variant="h1" className={classes.aboutUsQuoteText}>
            a nossa mais elevada tarefa deve ser a de formar seres humanos
            livres que sejam capazes de, por si mesmos, encontrar propósito e
            direção para suas vidas.
          </Typography>
          <Typography variant="h5" className={classes.aboutUsQuoteSubtitleText}>
            Rudolf Steiner
          </Typography>
          <img
            src={closeQuotesIcon}
            alt="Fecha aspas"
            className={classes.closeQuotesIcon}
          />
        </div>
        <div className={classes.missionArea}>
          <div className={classes.missionItem}>
            <Typography
              className={classes.missionItemTitle}
              variant="h2"
              style={{
                color: theme.palette.secondary.main,
              }}
            >
              missão
            </Typography>
            <Typography className={classes.missionItemDescription} variant="h6">
              Ajudar no desenvolvimento biológico e psíquico dos bebês,
              respeitando seu tempo e suas habilidades a cada mês de vida para
              que se tornem adultos capazes e auto-confiantes
            </Typography>
          </div>
          <div className={classes.missionItem}>
            <Typography
              className={classes.missionItemTitle}
              variant="h2"
              style={{
                color: "#EF835B",
              }}
            >
              visão
            </Typography>
            <Typography className={classes.missionItemDescription} variant="h6">
              Ser reconhecida em todo o território brasileiro pelo respeito à
              infância como período primordial no desenvolvimento do sujeito,
              entregando brinquedos adequados a cada bebê.
            </Typography>
          </div>
          <div className={classes.missionItem}>
            <Typography
              className={classes.missionItemTitle}
              variant="h2"
              style={{
                color: theme.palette.primary.main,
              }}
            >
              valores
            </Typography>
            <Typography className={classes.missionItemDescription} variant="h6">
              Respeito, excelência, qualidade, cuidado e carinho
            </Typography>
          </div>
        </div>
        <div className={classes.teamArea}>
          <Hidden smDown>
            <div className={classes.teamMembersImageArea}>
              <img
                src={teamMembersImage}
                alt="equipe de especialistas"
                className={classes.teamMembersImage}
              />
            </div>
          </Hidden>
          <div className={classes.teamMembersInfoArea}>
            <Typography
              variant="h1"
              className={classes.specialistTeamTitleText}
            >
              equipe de especialistas
            </Typography>
            <div className={classes.specialistContainer}>
              <Typography
                variant="h3"
                className={classes.teamSpecialistNameText}
              >
                Camila Cerqueira
              </Typography>
              <Typography
                className={classes.teamSpecialistDescriptionParagraph}
                variant="h5"
              >
                É a mãe disposta e criativa! A que pensa em todos os detalhes da
                festinha e não tem medo de colocar a mão na massa. Inventa
                atividades, organiza tudo com cuidado. Estudou sobre como se
                comunicar e se comunica em inglês com a Laís e com a Lisa, para
                que não lhes falte autonomia em nenhum lugar do mundo!
              </Typography>
              <Typography
                variant="h5"
                className={classes.teamSpecialistDescriptionParagraph}
              >
                Estudiosa do método Montessori, sua casa já era toda preparada
                para crianças muito antes da Smart Baby!
              </Typography>
            </div>
            <div className={classes.specialistContainer}>
              <Typography
                variant="h3"
                className={classes.teamSpecialistNameText}
              >
                Carolina Medeiros
              </Typography>
              <Typography
                className={classes.teamSpecialistDescriptionParagraph}
                variant="h5"
              >
                Ela é a mãe que não deixa passar nenhum detalhe. Ela tem toda a
                empatia de uma mãe que é ouvido e voz para tantas outras.
                Conhecedora das mentes, é especialista sobre o enorme potencial
                que os cérebros dos bebês possuem nos primeiros anos de vida.
              </Typography>
              <Typography
                variant="h5"
                className={classes.teamSpecialistDescriptionParagraph}
              >
                Ela é uma mente pensante e operante. Já era smart desde baby.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default Sobre;
